.loginInput{
  width: 100%;
}

.loginForm{
  width: 20%;
  margin: 3rem auto;
}

.form-signin{
  flex-direction: column;
  height: 100vh;
  #logo{
    width: 10%;
  }
  align-items: center;
  justify-content: center;
}