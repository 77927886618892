.Page{
    width: 75%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
}

#heading{
    margin-top: 2rem;
    margin: 2rem auto;
}

.subHeadings{
    margin: 2rem 4rem;
}

#headCont{
    width: 90%;
    margin: 0 auto;
}

.save-button{
    width: 20%;
    margin: 2rem auto;
}

.contactCard{
    width: 90%;
    margin: 1rem auto;
    border: 0.1rem solid black;
}

.form{
    width: 90%;
    margin: 1rem auto;
}

