.galleryContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .row{
        width: 90%;
        margin-top: 2rem;
        overflow-y: scroll;

        .card{
            object-fit: cover;
        }
    }
}